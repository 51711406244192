import request from '@/utils/request'

// 根据id删除菜单信息
export function delMenu(id) {
  return request({
    method: 'get',
    url: `/auth/menu/delMenu`,
    params:{
      id
    }
  })
}

// 查询所有菜单信息
export function findAllMenu() {
  return request({
    method: 'get',
    url: `/auth/menu/findAllMenu`,
  })
}

// 查询菜单信息(回显)
export function findMenuInfoById(id=-1) {
  return request({
    method: 'get',
    // url: `/auth/menu/findMenuInfoById?id=${id}`,
    url: `/auth/menu/findMenuInfoById`,
    params:{
      id
    },
  })
}

// 添加&修改菜单
export function saveOrUpdateMenu(data) {
  return request({
    method: 'post',
    url: `/auth/menu/saveOrUpdateMenu`,
    data
  })
}
