<template>
  <div class="menu">
    <el-card class="box-card" >
      <div slot="header" class="clearfix">
        <el-button @click="$router.push({name:'addMenu'})">添加菜单</el-button>
      </div>
      <!--      底部菜单列表展示区-->
      <el-table
        :data="tableData"
        height="720"
        border
        style="width: 100%">
        <el-table-column type="index" label="编号"></el-table-column>
        <el-table-column prop="name" label="菜单名称"></el-table-column>
        <el-table-column prop="level" label="菜单级数"></el-table-column>
        <el-table-column prop="icon" label="前端图标"></el-table-column>
        <el-table-column prop="orderNum" label="排序"></el-table-column>
        <el-table-column prop="shown" label="是否展示"></el-table-column>
        <el-table-column label="操作" width="180" fixed="right">
          <template slot-scope="{ row }">
            <el-button @click="handleEdit(row)">编辑</el-button>
            <el-button @click="handleDelete(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { findAllMenu,delMenu } from '@/services/menu'

export default {
  name: 'MenuIndex',
  data () {
    return {
      tableData: []
    }
  },
  created () {
    this.loadingAllMenu()
  },
  methods: {
    async loadingAllMenu () {
      // 加载所有菜单信息
      const { data } = await findAllMenu()
      if (200 === data.code) {
        return this.tableData = data.data
      } else {
        this.$message.error(data.message)
      }
    },
    handleEdit (row) {
      // 设置跳转
      this.$router.push({
        name:'updateMenu',
        params: {
          id: row.id
        }
      })
    },
    async handleDelete (id) {
      try {
        // 删除的确认提示
        await this.$confirm('确认要删除吗?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        // 发送删除请求
        const {data} = await delMenu(id)
        if (200===data.code) {
          this.$message.success('删除成功！')
          await this.loadingAllMenu()
        } else {
          this.$message.error('删除失败')
        }
      } catch (err) {
        if ('cancel' === err) {
          this.$message.info('已取消删除！')
        } else {
          this.$message.error(err)
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.menu {
  .el-card {
    background-color: #f8f9fb;
  }
}
</style>
